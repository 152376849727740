<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant
            type="search"
            name="plantCd"
            v-model="searchParam.plantCd"
          ></c-plant>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            type="year"
            default="today"
            label="년도"
            name="inspectionYear"
            v-model="searchParam.inspectionYear"
          />
        </div>
      </template>
    </c-search-box>
    <q-table
      grid
      title=""
      :data="grid.data"
      :columns="grid.columns"
      hide-header
      hide-bottom
      :rows-per-page-options="[0]"
      virtual-scroll
    >
      <template v-slot:item="props">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
            <q-card-section class="grid-card-etc">
              <div :class="['text-grid-etc', 'txt-box-grid text-primary-box']">
                {{ props.row.inspectionYear }}
              </div>
              <div :class="['text-grid-etc', 'txt-box-grid text-positive-box']">
                {{ props.row.locationRestFacilitiesName }}
              </div>
              <div v-if="props.row.checkerName" :class="['text-grid-etc', 'txt-box-grid text-gray-box']">
                {{ props.row.checkerName }}
              </div>
            </q-card-section>
            <q-card-section>
              <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.hygieneInspectionName }}</div>
            </q-card-section>
          </q-card>
        </div>
      </template>
    </q-table>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
      <q-card class="mobileTableCardLayer">
        <q-card-section horizontal class="bg-grey-2 text-center">
          <q-card-section class="q-pt-xs">
            <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
export default {
  name: 'hygieneInspection',
  mixins: [mixinFooterSearch],
  data() {
    return {
      searchParam: {
        plantCd: null,
        inspectionYear: '',
        stepCd: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'hygieneInspectionName',
            field: 'hygieneInspectionName',
            label: '위생점검명',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: 'locationRestFacilitiesName',
            field: 'locationRestFacilitiesName',
            label: '휴게시설',
            align: 'left',
            sortable: true,
            style: 'width: 180px',
          },
          {
            name: 'inspectionYear',
            field: 'inspectionYear',
            label: '년도',
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: 'checkerName',
            field: 'checkerName',
            label: '점검자',
            style: 'width:130px',
            align: "center",
            sortable: true,
          },
          // {
          //   name: 'stepName',
          //   field: 'stepName',
          //   label: '진행단계',
          //   style: 'width:100px',
          //   align: "center",
          //   sortable: true,
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        width: '70%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.rtf.his.hygieneInspection.list.url
      this.detailUrl = selectConfig.sop.rtf.restFacilities.get.url
      // code setting
      if (this.$route.query.sopRestFacilitiesId) {
        this.$http.url = this.$format(this.detailUrl, this.$route.query.sopRestFacilitiesId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data.sopRestFacilitiesHygieneInspectionId) {
            this.linkClick({
              sopRestFacilitiesHygieneInspectionId: _result.data.sopRestFacilitiesHygieneInspectionId
            });
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '해당 휴게시설에 할당된 위생점검이 없습니다.',
              type: 'warning', // success / info / warning / error
            });
          }
        },);
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '휴게시설 위생점검표';
      this.popupOptions.param = {
        sopRestFacilitiesHygieneInspectionId: row ? row.sopRestFacilitiesHygieneInspectionId : '',
      };
      this.popupOptions.target = () => import(`${'./hygieneInspectionDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
